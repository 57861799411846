<template>
	<div class="center">
		<div v-if="isPrint">
			<!-- <div style="text-align: center;font-size:20px;margin: 25px auto;">{{year}}兰溪市部分国有企业公开招聘工作人员考试</div> -->
			<div style="text-align: center;font-size:20px;margin: 25px auto;">{{year}}部分国有(集体)企业公开招聘工作人员考试</div>
			<div style="text-align: center;font-size:18px;margin: 15px auto;font-weight: bold;">准 考 证</div>
			<div style="text-align: center;font-size:16px;margin: 25px auto;font-weight: bold;">此证必须与有效身份证同时使用<br>缺一不得进入考场
			</div>
			<table class="eltable" border="1" cellpadding="5" cellspacing="0" align="center">
				<col width="160">
				<col width="180">
				<col width="100">
				<col width="80">
				<col width="80">
				<col width="100">
				<tr>
					<td>姓名</td>
					<td colspan="4">{{info.name}}</td>
					<td rowspan="5">
						<img :src="setuserlogo(info.avatar)" fit="contain" width="100" height="120"></img>
					</td>
				</tr>
				<tr>
					<td>身份证号</td>
					<td colspan="4">{{info.idcard}}</td>
				</tr>
				<tr>
					<td>准考证号</td>
					<td colspan="4">{{Audit.ticketNo}}</td>
				</tr>
				<tr>
					<td>报考单位</td>
					<td colspan="2">{{Audit.examCompanyId}}</td>
					<td>考场号</td>
					<td>{{Audit.roomNum}}</td>
				</tr>
				<tr>
					<td>报考职位</td>
					<td colspan="2">{{Audit.examCompanyItemName}}</td>
					<td>座位号</td>
					<td>{{Audit.seatNum}}</td>
				</tr>
				<tr>
					<td>考试地址</td>
					<td colspan="5">{{form.address}}</td>
				</tr>
				<tr>
					<td>考试科目</td>
					<td>考试日期</td>
					<td colspan="2">考试时间</td>
					<td colspan="2">监考人员收卷签名</td>
				</tr>
				<tr>
					<td>{{form.courseExam}}</td>
					<td>{{form.courseDate}}</td>
					<td colspan="2">{{form.courseTime}}</td>
					<td colspan="2"></td>
				<tr>
					<td colspan="6" style="font-weight: bold;">每场考试交卷后，此证须经监考人员收卷签名，否则，答卷缺少责任由考生自负。</td>
				</tr>
			</table>
			<div class="msg" v-html="return2Br(form.content)"></div>
			<div class="printbtn" style="text-align:center">
				<el-button class="footer-btn" @click="print()">打 印</el-button>
			</div>
		</div>
		<div v-else style="text-align: center;line-height: 10;">
			<span style="font-size: 33px;">不在打印时间范围内，无法打印准考证！</span>
		</div>
	</div>
</template>

<script>
	import {
		isnull
	} from "@/assets/js/index";
	import {
		GetExamModel,
		ExamSignUpAudit,
		ExamSignUpInfo,
	} from "@/api/user";
	import global from "@/assets/js/globalconfig";
	export default {
		data() {
			return {
				defimgurl: global.baseimgurl,
				baseurl: global.baseurl,
				year: "",
				Audit:{},
				info: {},
				form: {},
				printbtn: true,
				isPrint: false,
			}

		},
		mounted() {},
		created() {
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.userInfo = JSON.parse(localStorage.getItem("userinfo"));
				this.form.personId = parseInt(this.userInfo.peR_ID)
				this.ExamSignUpAudit(this.$route.query.id);
			}
		},
		methods: {
			print() {
				print()
			},
			return2Br(str) {
				return str.replace(/\r?\n/g, "<br />");
			},
			setuserlogo(url) {
				var data = isnull(url) ? 0 : url.indexOf("http");
				return data < 0 ?
					this.defimgurl + url :
					url;
			},
			goto(url, data, type = 2) {
				this.resolvewin(url, data, type);
			},
			Getinfo(id) {
				GetExamModel({
					id: id,
					types:'Prin'
				}).then((res) => {
					if (res.success) {
						this.form = res.data[0];
						if (this.form.state == 2) {
							this.$message({
								message: '打印准考证未开始！即将跳转报名记录页面。。。', //提示的信息
								type: 'success',
								duration: 3000,
								isshow: true,
								onClose: () => {
									this.goto("usercenter", {
										type: 7
									}, 1);
								}
							});
							return;
						}
						if (this.form.state == 3) {
							this.$message({
								message: '打印准考证已结束！即将跳转报名记录页面。。。', //提示的信息
								type: 'success',
								duration: 3000,
								isshow: true,
								onClose: () => {
									this.goto("usercenter", {
										type: 7
									}, 1);
								}
							});
							return;
						}
						this.isPrint = true;
					}
				});
			},
			ExamSignUpAudit(id) {
				var _this = this;
				var par = {
					id: id
				};
				ExamSignUpAudit(par).then((res) => {
					if (res.success) {
						if (res.data!=null) {
							_this.Audit = res.data;
							this.Getinfo(res.data.examId)
							this.ExamSignUpInfo(res.data.signUpId)
							this.year = _this.Audit.createTime.substring(0, 4)
						} else {
							this.$message.error("没有报名信息！");
						}
					}
				})
			},
			ExamSignUpInfo(id) {
				var _this = this;
				var par = {
					KW: this.form.personId,
					id: id
				};
				ExamSignUpInfo(par).then((res) => {
					if (res.success) {
						if (res.data!=null) {
							_this.info = res.data;
							//this.year = _this.info.createTime.substring(0, 4)
						} else {
							this.$message.error("没有报名信息！");
						}
					}
				})
			}

		}
	}
</script>
<style>
	@media print{
		.printbtn{display: none;}
	}
	
	html {
		background-color: #fff;
		font-family: "宋体";
	}

	.eltable {
		text-align: center;
		border-collapse: collapse;
		font-size: 12px;
	}

	.eltable tr td {
		padding: 0px 10px;
		height: 40px;
		line-height: 40px;
	}

	.msg {
		width: 500px;
		margin: 15px auto 0;
		font-size: 14px;
		line-height: 30px;
	}
</style>
